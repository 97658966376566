@import "~bootstrap/scss/bootstrap";

// KAPELLA
@import "common";
@import "horizontal-wrappers";
@import "horizontal-menu";

// FONTS fontawesome e MDI
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@mdi/font/scss/materialdesignicons';
@import '~mdi-icons/css/materialdesignicons.min.css';
