@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/sourcesanspro/SourceSansPro-Black.eot');
  src: url('../fonts/sourcesanspro/SourceSansPro-Black.eot?#iefix') format('embedded-opentype'),
      url('../fonts/sourcesanspro/SourceSansPro-Black.woff2') format('woff2'),
      url('../fonts/sourcesanspro/SourceSansPro-Black.woff') format('woff'),
      url('../fonts/sourcesanspro/SourceSansPro-Black.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/sourcesanspro/SourceSansPro-Bold.eot');
  src: url('../fonts/sourcesanspro/SourceSansPro-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/sourcesanspro/SourceSansPro-Bold.woff2') format('woff2'),
      url('../fonts/sourcesanspro/SourceSansPro-Bold.woff') format('woff'),
      url('../fonts/sourcesanspro/SourceSansPro-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/sourcesanspro/SourceSansPro-ExtraLight.eot');
  src: url('../fonts/sourcesanspro/SourceSansPro-ExtraLight.eot?#iefix') format('embedded-opentype'),
      url('../fonts/sourcesanspro/SourceSansPro-ExtraLight.woff2') format('woff2'),
      url('../fonts/sourcesanspro/SourceSansPro-ExtraLight.woff') format('woff'),
      url('../fonts/sourcesanspro/SourceSansPro-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/sourcesanspro/SourceSansPro-Light.eot');
  src: url('../fonts/sourcesanspro/SourceSansPro-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/sourcesanspro/SourceSansPro-Light.woff2') format('woff2'),
      url('../fonts/sourcesanspro/SourceSansPro-Light.woff') format('woff'),
      url('../fonts/sourcesanspro/SourceSansPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/sourcesanspro/SourceSansPro-SemiBold.eot');
  src: url('../fonts/sourcesanspro/SourceSansPro-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/sourcesanspro/SourceSansPro-SemiBold.woff2') format('woff2'),
      url('../fonts/sourcesanspro/SourceSansPro-SemiBold.woff') format('woff'),
      url('../fonts/sourcesanspro/SourceSansPro-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/sourcesanspro/SourceSansPro-Regular.eot');
  src: url('../fonts/sourcesanspro/SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/sourcesanspro/SourceSansPro-Regular.woff2') format('woff2'),
      url('../fonts/sourcesanspro/SourceSansPro-Regular.woff') format('woff'),
      url('../fonts/sourcesanspro/SourceSansPro-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

